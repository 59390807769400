{
  axios.get('common/data/top.json')
    .then(function (response) {
      // ビデオ
      new Vue({
        el: '.videoWrap',
        data: {
          video: response.data.video
        },
        computed: {
          videoStyle () {
            if (this.video.type === 'over') {
              return { position: 'absolute' }
            } else if (this.video.type === 'top') {
              return { position: 'relative' }
            }
          }
        }
      })

      // メインビジュアル
      // 処理が複雑になるので、pcとspで要素ごと別にする
      new Vue({
        el: '.mainVisual--pc',
        data: {
          mainVisual: response.data.mainVisual,
        },
        methods: {
          mainVisualEleOver (index) {
            this.mainVisual[index].active = true;
          },
          mainVisualEleLeave (index) {
            this.mainVisual[index].active = false;
          }
        },
        computed: {
          calcCssHeightString () {
            let extraGap =  60 + ((this.mainVisual.length - 1) * 1);
            let calcVal = `calc((100vh - ${extraGap}px) / ${this.mainVisual.length})` // 40px + 2px * 5
            return `height: ${calcVal}; line-height: ${calcVal};`
          }
        }
      })

      new Vue({
        el: '.mainVisual--sp',
        data: {
          mainVisual: response.data.mainVisual,
        },
        computed: {
          calcCssHeightString () {
            let extraGap =  10 + ((this.mainVisual.length - 1) * 2);
            let calcVal = `calc((100vh - ${extraGap}px) / ${this.mainVisual.length})` // 40px + 2px * 5
            return `height: ${calcVal}; line-height: ${calcVal};`
          }
        },
        created () {
          // 画像のプリロード
          let images = []
          this.mainVisual.forEach((ele, index) => {
            images[index] = new Image()
            images[index].src = 'top/img/main/' + ele.img
          })
        },
      })

      // カテゴリー
      new Vue({
        el: '.categoryWrap',
        data: {
          category: response.data.category
        }
      })

      // スライダー
      new Vue({
        el: '.swiperWrap',
        data: {
          slider: response.data.slider
        },
        mounted () {
          // スライダー
          new Swiper('.swiper', {
            slidesPerView: 'auto',
            centeredSlides: true,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }
          })
        }
      })

      // ブランド
      new Vue({
        el: '.brandsWrap',
        data: {
          brands: response.data.brands
        },
        mounted () {
          ScrollReveal().reveal('.brands__ele', {
            distance: '60px',
            interval: 180,
            duration: 1000,
            viewFactor: 0.3,
          });
        }
      })
    })
}
